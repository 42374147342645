body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
} */
@-ms-viewport {
    min-width: 100rem;
    position: relative;
}

@viewport {
    min-width: 100rem;
    position: relative;
}

/* Controllato da Dashboard.css */
body {
    background-color: none;
    /* background-image: url("/assets/images/background_login.png"); */
    background-repeat: repeat;
    background-size: 100vw;
}

.logo_login {
    width: 10rem;
    padding-top: 0.5rem;
    padding-left: 24rem;
}

.logo_login_mobile {
    width: 10rem;
    padding-top: 0.5rem;
    padding-left: calc(95vw - 13rem);
}

.container {
    display: flex;
}

.login_field {
    position: absolute;
    width: 32rem;
    height: 24rem;
    background-color: black;
    /* rgb(51, 51, 51); */
    padding: 2.5rem;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: auto;
    margin-top: calc(10vh);
    font-size: 1rem;
    border-radius: 0.8rem;
    color: white;
}

.login_field_mobile {
    width: calc(95vw - 1rem);
    height: 24rem;
    background-color: black;
    /* rgb(51, 51, 51); */
    margin-top: 20vh;
    padding: 2rem;
    border-radius: 0.8rem;
    color: white;
}

.text {
    text-align: left;
    padding-top: 1rem;
    padding-left: 1rem;
    font-size: 1.2rem;
}

.title {
    text-align: center;
    font-size: 4rem;
}

.field {
    display: block;
    text-indent: 1rem;
    padding: 0.5rem;
    border-radius: 2rem;
    font-size: 2rem;
    width: 31.3rem;
}

.field_mobile {
    text-indent: 1rem;
    border-radius: 2rem;
}

.accedi {
    cursor: pointer;
    background-color: #e90006;
    color: white;
    padding: 1rem 3rem;
    margin-top: 0.4rem;
    margin-left: 10.5rem;
    text-align: center;
    font-size: 2rem;
    border-radius: 2rem;
    border:rgb(51, 51, 51);
}

.accedi_mobile {
    cursor: pointer;
    background-color: #e90006;
    color: white;
    padding: 1rem 3rem;
    margin-top: 0.4rem;
    margin-left: calc((100% - 11rem)/2);
    text-align: center;
    font-size: 2rem;
    border-radius: 2rem;
    border:rgb(51, 51, 51);
}
@-ms-viewport {
    min-width: 100rem;
    position: relative;
}

@viewport {
    min-width: 100rem;
    position: relative;
}

body {
    background-image: none;
    background-color: black;
    background-repeat: repeat;
}

.container {
    display: flex;
}

.left_block {
    position: absolute;
    width: 16rem;
    height: calc(100% - 3rem);
    margin-top: 1rem;
    margin-left: 1rem;
    background-color: #E44311;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-radius: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.left_block::-webkit-scrollbar {
    display: none;
}

.right_block {
    justify-content: space-between;
    display: block;
    position: absolute;
    width: calc(100% - 30rem);
    height: calc(100% - 8rem);
    margin-top: 1rem;
    margin-left: 23.2rem;
    background-color: rgb(51, 51, 51);
    padding: 2.5rem;
    border-radius: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.testo {
    margin-top: 1rem;
    margin-left: 2rem;
    font-size: 2.5rem;
    text-align: left;
}

.testo_mobile {
    margin-left: 0.5rem;
    font-size: 2rem;
    padding-top: 0rem;
    margin-top: 0.5rem;
    text-align: left;
}

.nuova_stazione{
    cursor: pointer;
    width: 22.8rem;
    margin-right: 1.5rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    padding: 1rem 3rem;
    font-size: 2rem;
}

.nuova_stazione::before {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    content: "";
    background: url(/assets/images/petrol_station.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
}

.nuova_stazione_mobile{
    background-image: url(/assets/images/add_white.png);
    height: 3rem;
    width: 5rem;
    background-size: 2.2rem 2.2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    background-position: center;
}

.search_mobile{
    background-image: url(/assets/images/search.png);
    height: 3rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: rgb(51, 51, 51);
    border: none;
    background-position: center;
}

.flex {
    display : flex;
    justify-content: space-between;
    height: 5rem;
}

.flex_mobile {
    display : flex;
    justify-content: space-between;
    height: 3rem;
}

.logo_dashboard {
    width: 15rem;
    padding-bottom: 3rem;
    padding-top: 1rem;
    padding-left: 0.6rem;
}

.bottone_laterale{
    cursor: pointer;
    margin-right: 1.5rem;
    text-align: center;
    border-radius: 0.8rem;
    width: 100%;
    padding: 0.8rem;
    border: none;
    color: white;
    font-size: 2rem;
}

.bottone_laterale::before {
    display: inline-block;
    margin-right: 1rem;
    content: "";
    background-repeat: no-repeat;
}

.petrol_station::before{
    background: url(/assets/images/petrol_station.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.sessions::before{
    background: url(/assets/images/charger.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.transactions::before{
    background: url(/assets/images/transactions.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.lista_stazioni{
    height: calc(100% - 7rem);
    border-radius: 1rem;
    background-color: rgb(51, 51, 51);
    overflow-x: hidden;
    overflow-y: scroll;
}

.lista_stazioni::-webkit-scrollbar {
    display: none;
}

.elementi_lista_stazioni{
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    height: 6.5rem;
    border-radius: 1rem;
    background-color: white;
    color: black;
    /*overflow-y: scroll;*/
}

.elementi_lista_stazioni::-webkit-scrollbar {
    display: none;
}

.elementi_lista_stazioni_mobile{
    width: calc(100% - 1rem);
    margin-bottom: 1rem;
    padding: 0.5rem;
    height: 6.5rem;
    border-radius: 1rem;
    background-color: white;
    color: black;
    overflow-y: scroll;
    overflow-x: hidden;
}

.elementi_lista_stazioni_mobile::-webkit-scrollbar {
    display: none;
}

.left-block_lista{
    margin-top: 0.32rem;
    padding-left: 0.6rem;
    padding-top: 0.01rem;
    text-align: left;
    width: calc(100% - 15rem);
    height: 5rem;
    overflow-x: scroll;
}

.left-block_lista::-webkit-scrollbar {
    display: none;
}

.right-block_lista{
    display: flex;
    margin-top: 0.32rem;
    padding-right: 0.6rem;
    padding-top: 0.01rem;
    text-align: left;
    width: 12.5rem;
    height: 5rem;
}

.space {
    height: 2rem;
}

.caricatori{
    padding-top: 0rem;
    text-align: center;
    font-size: 1.2rem;
}

.info_image{
    cursor: pointer;
    border: none;
    background: url(/assets/images/info.png);
    margin-left: 2.25rem;
    margin-top: 0.25rem;
    background-size: 4.5rem 4.5rem;
    background-repeat: no-repeat;
    width: 4.5rem;
    height: 4.5rem;
}

.info_image_mobile{
    cursor: pointer;
    border: none;
    background: url(/assets/images/info.png);
    margin-left: 0.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.salva{
    cursor: pointer;
    margin-right: 1.5rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    height: 4rem;
    padding: 0rem 2rem;
    font-size: 2rem;
}

.salva::before {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    content: "";
    background: url(/assets/images/save.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
}

.salva_mobile {
    background-image: url(/assets/images/save.png);
    height: 2.5rem;
    width: 4rem;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    background-position: center;
}

.delete {
    background-image: url(/assets/images/bin.png);
    height: 4rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.delete_mobile {
    background-image: url(/assets/images/bin.png);
    height: 2.5rem;
    width: 3rem;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.back{
    cursor: pointer;
    align-items: left;
    background-color: rgb(51, 51, 51);
    width: 7rem;
    text-align: left;
    border: none;
    color: white;
    font-size: 1.2rem;
}

.back::before {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    content: "";
    background: url(/assets/images/back.png);
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
}

.field_mod {
    border: none;
    width: calc(100% - 25rem);
    height: 4.2rem;
    background-color: white;
    font-size: 2rem;
    border-radius: 4rem;
    padding-left: 1.2rem;
    text-decoration: underline;
    -webkit-text-decoration-color: black;
            text-decoration-color: black;
    color: black;
}

.field_mod:-ms-input-placeholder {
    text-decoration-color: #777777;
}

.field_mod:placeholder-shown {
    -webkit-text-decoration-color: #777777;
            text-decoration-color: #777777;
}

.field_mobile {
    border: none;
    width: calc(100% - 1rem);
    height: 3rem;
    background-color: white;
    font-size: 1.5rem;
    border-radius: 4rem;
    padding-left: 0.2rem;
    text-decoration: underline;
    -webkit-text-decoration-color: black;
            text-decoration-color: black;
    color: black;
}

.field_mobile:-ms-input-placeholder {
    text-decoration-color: #777777;
}

.field_mobile:placeholder-shown {
    -webkit-text-decoration-color: #777777;
            text-decoration-color: #777777;
}

.fields_generali{
    border: none;
    float: right;
    width: calc(50vw - 18rem);
    height: 4.2rem;
    background-color: white;
    font-size: 2rem;
    border-radius: 4rem;
    padding-left: 1.2rem;
    text-decoration: underline;
    -webkit-text-decoration-color: black;
            text-decoration-color: black;
    color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.aggiungi{
    cursor: pointer;
    margin-right: 2rem;
    margin-top: 3.2rem;
    text-align: center;
    border-radius: 2rem;
    background-color: white;
    border: none;
    color: black;
    height: 2.5rem;
    padding: 0rem 1.5rem;
    font-size: 1rem;
}

.aggiungi::before {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
    content: "";
    background: url(/assets/images/add.png);
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
}

.aggiungi_mobile {
    background-image: url(/assets/images/add.png);
    margin-top: 0.4rem;
    height: 2rem;
    width: 3rem;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: white;
    border: none;
    background-position: center;
}

.messaggio{
    margin-top: 3rem;
    text-align: center;
    border: none;
    color: #777777;
    font-size: 2rem;
}

.status_type{
    border: none;
    margin-left: 0.25rem;
    background-size: 3.2rem 3.2rem;
    background-repeat: no-repeat;
    width: 3.2rem;
    height: 3.2rem;
}

.status_type_mobile{
    border: none;
    margin-left: 0.25rem;
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
}

.mod_charger{
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    height: calc(100% - 21.5rem);
    border-radius: 1rem;
    background-color: white;
    overflow-y: scroll;
}

.mod_charger::-webkit-scrollbar {
    display: none;
}

.plugs_container{
    display: flex;
    width: calc(50vw - 18rem);
}

.plug_buttons{
    cursor: pointer;
    border-radius: 4rem;
    border: none;
    height: 4rem;
    width: 6rem;
    margin-left: 5%;
    margin-right: 5%;
}

.type{
    border: none;
    padding-left: 0.1rem;
    background-size: 3.2rem 3.2rem;
    background-repeat: no-repeat;
    width: 3.2rem;
    height: 3.2rem;
}

.conferma{
    cursor: pointer;
    margin-top: 3.8rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    height: 3rem;
    width: 11rem;
    font-size: 1.8rem;
}

.conferma_mobile{
    cursor: pointer;
    margin-top: 0.8rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    height: 3rem;
    width: 11rem;
    font-size: 1.8rem;
}

.elimina{
    background-image: url(/assets/images/bin.png);
    height: 3rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 3.8rem;
    margin-left: 15rem;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.elimina_mobile{
    background-image: url(/assets/images/bin.png);
    height: 3rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 0.8rem;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.lista_transazioni_sessioni{
    height: calc(100% - 11rem);
    border-radius: 1rem;
    background-color: rgb(51, 51, 51);
    overflow-x: hidden;
    overflow-y: scroll;
}

.lista_transazioni_sessioni::-webkit-scrollbar {
    display: none;
}

.elementi_lista_transazioni {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    height: 4rem;
    border-radius: 1rem;
    background-color: white;
    color: black;
}

.table_element {
    text-align: center;
    margin: 0.5rem;
    margin-top: 1.1rem;
    height: 2rem;
    font-size: 1.6rem;
    background-color: white;
    color: black;
    overflow: hidden;
}

.topBar {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    height: 3rem;
    border-radius: 1rem;
    background-color: #D2D2D2;
    color: black;
    overflow-y: hidden;
}

.topBar::-webkit-scrollbar {
    display: none;
}

.topBar_container{
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
}

.topBar_container::-webkit-scrollbar {
    display: none;
}

.right-block-table{
    overflow-x: scroll;
}

.right-block-table::-webkit-scrollbar {
    display: none;
}

.bar_element {
    text-align: center;
    margin: 0.5rem;
    margin-top: 0.8rem;
    height: 1.2rem;
    font-size: 1.2rem;
    background-color: #D2D2D2;
    color: black;
    overflow: hidden;
}

.popup {
    width: 40rem;
    height: 25rem;
    border-radius: 1rem;
    background-color: #F2F2F2;
    color: black;
    box-shadow: black 0px 54px 55px, black 0px -12px 30px, black 0px 4px 6px, black 0px 12px 13px, black 0px -3px 5px;
}

.popup_mobile {
    width: calc(100% - 3.2rem);
    height: 100%;
    padding-bottom: 1rem;
    margin-left: 1.4rem;
    background-color: #F2F2F2;
    border-radius: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.popup_mobile::-webkit-scrollbar {
    display: none;
}

.popup_title {
    font-weight: bold;
    padding-top: 0.5rem;
    font-size: 3.5rem;
    color: #C93F10;
    text-align: center;
}

.popup_button{
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 2rem;
    text-align: center;
    border-radius: 4rem;
    border: none;
    color: white;
    height: 3rem;
    width: 11rem;
    font-size: 1.8rem;
}

.popup_actions{
    display: flex;
}

.admin{
    margin-top: 0.5rem;
    background: url(/assets/images/admin.png);
    background-size: 3rem 3rem;
    background-repeat: no-repeat;
    width: 4rem;
    height: 3rem;
}

.exit{
    cursor: pointer;
    margin-top: 0.5rem;
    border: none;
    margin-left: 3.3rem;
    background: url(/assets/images/exit.png);
    background-size: 2.8rem 2.8rem;
    background-repeat: no-repeat;
    width: 2.8rem;
    height: 2.8rem;
}

.up_block {
    display: flex;
    width: calc(100% - 1rem);
    height: 5rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    background-color: #E44311;
    border-radius: 0.8rem;
    color: white;
}

.menu_image{
    cursor: pointer;
    border: none;
    background: url(/assets/images/menu.png);
    margin-left: 2rem;
    margin-top: 1.25rem;
    background-size: 2.2rem 2.2rem;
    background-repeat: no-repeat;
    width: 2.2rem;
    height: 2.2rem;
}

.logo_dashboard_mobile{
    border: none;
    background: url(/assets/images/logo.png);
    background-size: 12rem, 4rem;
    margin-left: calc(50vw - 10rem);
    margin-top: 0.8rem;
    background-repeat: no-repeat;
    width: 15rem;
    height: 4rem;
}

.bottom_block_mobile{
    position: absolute;
    width: calc(100% - 3.8rem);
    height: calc(100% - 10rem);
    margin-top: 7rem;
    margin-left: 0.5rem;
    background-color: rgb(51, 51, 51);
    border-radius: 0.8rem;
    padding: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.bottom_block_mobile::-webkit-scrollbar {
    display: none;
}

.bottom_lista_mobile{
    display: flex;
    margin-top: 0.32rem;
    padding-right: 0.6rem;
    padding-top: 0.01rem;
    text-align: left;
    width: 100%;
    height: 3rem;
}

.flex_sessioni_transazioni{
    display: flex;
}

.search_bar {
    border: none;
    width: 100%;
    height: 2.5rem;
    margin-top: 0.5rem;
    background-color: white;
    font-size: 2rem;
    border-radius: 4rem;
    padding-left: 1.2rem;
    text-decoration: underline;
    -webkit-text-decoration-color: black;
            text-decoration-color: black;
    color: black;
}

.search_bar:-ms-input-placeholder {
    text-decoration-color: #777777;
}

.search_bar:placeholder-shown {
    -webkit-text-decoration-color: #777777;
            text-decoration-color: #777777;
}

.titles{
    margin: 0rem;
    margin-left: 2rem;
    font-size: 2.5rem;
    text-align: left;
}
.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: black; }
  .Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    .Toastify__toast-container--rtl {
      right: 0;
      left: auto;
      left: initial; } }

.Toastify__toast {
  position: relative;
  box-sizing: border-box;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 0.3rem;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  height: 3rem;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify__toast--rtl {
    direction: rtl; }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa; }
  .Toastify__toast--info {
    background: #3498db; }
  .Toastify__toast--success {
    background: #07bc0c; }
  .Toastify__toast--warning {
    background: #f1c40f; }
  .Toastify__toast--error {
    background: #F2F2F2;
    /* background: #C93F10; */
  }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #F2F2F2;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@-webkit-keyframes Toastify__trackProgress {
  0% {
    width: 100%; }
  100% {
    width: 0; } }

@keyframes Toastify__trackProgress {
  0% {
    width: 100%; }
  100% {
    width: 0; } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  -webkit-animation: Toastify__trackProgress linear 1;
          animation: Toastify__trackProgress linear 1;
  background-color: rgba(0, 0, 0, 0.7); }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: auto;
    left: initial; }
  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@-webkit-keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@-webkit-keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@-webkit-keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@-webkit-keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

@keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown; }

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut; }

@-webkit-keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@-webkit-keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut; }

@-webkit-keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }

@-webkit-keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0); } }

@-webkit-keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0); } }

@-webkit-keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
