@viewport {
    min-width: 100rem;
    position: relative;
}

/* Controllato da Dashboard.css */
body {
    background-color: none;
    /* background-image: url("/assets/images/background_login.png"); */
    background-repeat: repeat;
    background-size: 100vw;
}

.logo_login {
    width: 10rem;
    padding-top: 0.5rem;
    padding-left: 24rem;
}

.logo_login_mobile {
    width: 10rem;
    padding-top: 0.5rem;
    padding-left: calc(95vw - 13rem);
}

.container {
    display: flex;
}

.login_field {
    position: absolute;
    width: 32rem;
    height: 24rem;
    background-color: black;
    /* rgb(51, 51, 51); */
    padding: 2.5rem;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: auto;
    margin-top: calc(10vh);
    font-size: 1rem;
    border-radius: 0.8rem;
    color: white;
}

.login_field_mobile {
    width: calc(95vw - 1rem);
    height: 24rem;
    background-color: black;
    /* rgb(51, 51, 51); */
    margin-top: 20vh;
    padding: 2rem;
    border-radius: 0.8rem;
    color: white;
}

.text {
    text-align: left;
    padding-top: 1rem;
    padding-left: 1rem;
    font-size: 1.2rem;
}

.title {
    text-align: center;
    font-size: 4rem;
}

.field {
    display: block;
    text-indent: 1rem;
    padding: 0.5rem;
    border-radius: 2rem;
    font-size: 2rem;
    width: 31.3rem;
}

.field_mobile {
    text-indent: 1rem;
    border-radius: 2rem;
}

.accedi {
    cursor: pointer;
    background-color: #e90006;
    color: white;
    padding: 1rem 3rem;
    margin-top: 0.4rem;
    margin-left: 10.5rem;
    text-align: center;
    font-size: 2rem;
    border-radius: 2rem;
    border:rgb(51, 51, 51);
}

.accedi_mobile {
    cursor: pointer;
    background-color: #e90006;
    color: white;
    padding: 1rem 3rem;
    margin-top: 0.4rem;
    margin-left: calc((100% - 11rem)/2);
    text-align: center;
    font-size: 2rem;
    border-radius: 2rem;
    border:rgb(51, 51, 51);
}