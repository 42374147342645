@viewport {
    min-width: 100rem;
    position: relative;
}

body {
    background-image: none;
    background-color: black;
    background-repeat: repeat;
}

.container {
    display: flex;
}

.left_block {
    position: absolute;
    width: 16rem;
    height: calc(100% - 3rem);
    margin-top: 1rem;
    margin-left: 1rem;
    background-color: #E44311;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-radius: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.left_block::-webkit-scrollbar {
    display: none;
}

.right_block {
    justify-content: space-between;
    display: block;
    position: absolute;
    width: calc(100% - 30rem);
    height: calc(100% - 8rem);
    margin-top: 1rem;
    margin-left: 23.2rem;
    background-color: rgb(51, 51, 51);
    padding: 2.5rem;
    border-radius: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.testo {
    margin-top: 1rem;
    margin-left: 2rem;
    font-size: 2.5rem;
    text-align: left;
}

.testo_mobile {
    margin-left: 0.5rem;
    font-size: 2rem;
    padding-top: 0rem;
    margin-top: 0.5rem;
    text-align: left;
}

.nuova_stazione{
    cursor: pointer;
    width: 22.8rem;
    margin-right: 1.5rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    padding: 1rem 3rem;
    font-size: 2rem;
}

.nuova_stazione::before {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    content: "";
    background: url(/assets/images/petrol_station.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
}

.nuova_stazione_mobile{
    background-image: url(/assets/images/add_white.png);
    height: 3rem;
    width: 5rem;
    background-size: 2.2rem 2.2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    background-position: center;
}

.search_mobile{
    background-image: url(/assets/images/search.png);
    height: 3rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: rgb(51, 51, 51);
    border: none;
    background-position: center;
}

.flex {
    display : flex;
    justify-content: space-between;
    height: 5rem;
}

.flex_mobile {
    display : flex;
    justify-content: space-between;
    height: 3rem;
}

.logo_dashboard {
    width: 15rem;
    padding-bottom: 3rem;
    padding-top: 1rem;
    padding-left: 0.6rem;
}

.bottone_laterale{
    cursor: pointer;
    margin-right: 1.5rem;
    text-align: center;
    border-radius: 0.8rem;
    width: 100%;
    padding: 0.8rem;
    border: none;
    color: white;
    font-size: 2rem;
}

.bottone_laterale::before {
    display: inline-block;
    margin-right: 1rem;
    content: "";
    background-repeat: no-repeat;
}

.petrol_station::before{
    background: url(/assets/images/petrol_station.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.sessions::before{
    background: url(/assets/images/charger.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.transactions::before{
    background: url(/assets/images/transactions.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.lista_stazioni{
    height: calc(100% - 7rem);
    border-radius: 1rem;
    background-color: rgb(51, 51, 51);
    overflow-x: hidden;
    overflow-y: scroll;
}

.lista_stazioni::-webkit-scrollbar {
    display: none;
}

.elementi_lista_stazioni{
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    height: 6.5rem;
    border-radius: 1rem;
    background-color: white;
    color: black;
    /*overflow-y: scroll;*/
}

.elementi_lista_stazioni::-webkit-scrollbar {
    display: none;
}

.elementi_lista_stazioni_mobile{
    width: calc(100% - 1rem);
    margin-bottom: 1rem;
    padding: 0.5rem;
    height: 6.5rem;
    border-radius: 1rem;
    background-color: white;
    color: black;
    overflow-y: scroll;
    overflow-x: hidden;
}

.elementi_lista_stazioni_mobile::-webkit-scrollbar {
    display: none;
}

.left-block_lista{
    margin-top: 0.32rem;
    padding-left: 0.6rem;
    padding-top: 0.01rem;
    text-align: left;
    width: calc(100% - 15rem);
    height: 5rem;
    overflow-x: scroll;
}

.left-block_lista::-webkit-scrollbar {
    display: none;
}

.right-block_lista{
    display: flex;
    margin-top: 0.32rem;
    padding-right: 0.6rem;
    padding-top: 0.01rem;
    text-align: left;
    width: 12.5rem;
    height: 5rem;
}

.space {
    height: 2rem;
}

.caricatori{
    padding-top: 0rem;
    text-align: center;
    font-size: 1.2rem;
}

.info_image{
    cursor: pointer;
    border: none;
    background: url(/assets/images/info.png);
    margin-left: 2.25rem;
    margin-top: 0.25rem;
    background-size: 4.5rem 4.5rem;
    background-repeat: no-repeat;
    width: 4.5rem;
    height: 4.5rem;
}

.info_image_mobile{
    cursor: pointer;
    border: none;
    background: url(/assets/images/info.png);
    margin-left: 0.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.salva{
    cursor: pointer;
    margin-right: 1.5rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    height: 4rem;
    padding: 0rem 2rem;
    font-size: 2rem;
}

.salva::before {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    content: "";
    background: url(/assets/images/save.png);
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
}

.salva_mobile {
    background-image: url(/assets/images/save.png);
    height: 2.5rem;
    width: 4rem;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    background-position: center;
}

.delete {
    background-image: url(/assets/images/bin.png);
    height: 4rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.delete_mobile {
    background-image: url(/assets/images/bin.png);
    height: 2.5rem;
    width: 3rem;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.back{
    cursor: pointer;
    align-items: left;
    background-color: rgb(51, 51, 51);
    width: 7rem;
    text-align: left;
    border: none;
    color: white;
    font-size: 1.2rem;
}

.back::before {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    content: "";
    background: url(/assets/images/back.png);
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
}

.field_mod {
    border: none;
    width: calc(100% - 25rem);
    height: 4.2rem;
    background-color: white;
    font-size: 2rem;
    border-radius: 4rem;
    padding-left: 1.2rem;
    text-decoration: underline;
    text-decoration-color: black;
    color: black;
}

.field_mod:placeholder-shown {
    text-decoration-color: #777777;
}

.field_mobile {
    border: none;
    width: calc(100% - 1rem);
    height: 3rem;
    background-color: white;
    font-size: 1.5rem;
    border-radius: 4rem;
    padding-left: 0.2rem;
    text-decoration: underline;
    text-decoration-color: black;
    color: black;
}

.field_mobile:placeholder-shown {
    text-decoration-color: #777777;
}

.fields_generali{
    border: none;
    float: right;
    width: calc(50vw - 18rem);
    height: 4.2rem;
    background-color: white;
    font-size: 2rem;
    border-radius: 4rem;
    padding-left: 1.2rem;
    text-decoration: underline;
    text-decoration-color: black;
    color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.aggiungi{
    cursor: pointer;
    margin-right: 2rem;
    margin-top: 3.2rem;
    text-align: center;
    border-radius: 2rem;
    background-color: white;
    border: none;
    color: black;
    height: 2.5rem;
    padding: 0rem 1.5rem;
    font-size: 1rem;
}

.aggiungi::before {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
    content: "";
    background: url(/assets/images/add.png);
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
}

.aggiungi_mobile {
    background-image: url(/assets/images/add.png);
    margin-top: 0.4rem;
    height: 2rem;
    width: 3rem;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4rem;
    background-color: white;
    border: none;
    background-position: center;
}

.messaggio{
    margin-top: 3rem;
    text-align: center;
    border: none;
    color: #777777;
    font-size: 2rem;
}

.status_type{
    border: none;
    margin-left: 0.25rem;
    background-size: 3.2rem 3.2rem;
    background-repeat: no-repeat;
    width: 3.2rem;
    height: 3.2rem;
}

.status_type_mobile{
    border: none;
    margin-left: 0.25rem;
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
}

.mod_charger{
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    height: calc(100% - 21.5rem);
    border-radius: 1rem;
    background-color: white;
    overflow-y: scroll;
}

.mod_charger::-webkit-scrollbar {
    display: none;
}

.plugs_container{
    display: flex;
    width: calc(50vw - 18rem);
}

.plug_buttons{
    cursor: pointer;
    border-radius: 4rem;
    border: none;
    height: 4rem;
    width: 6rem;
    margin-left: 5%;
    margin-right: 5%;
}

.type{
    border: none;
    padding-left: 0.1rem;
    background-size: 3.2rem 3.2rem;
    background-repeat: no-repeat;
    width: 3.2rem;
    height: 3.2rem;
}

.conferma{
    cursor: pointer;
    margin-top: 3.8rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    height: 3rem;
    width: 11rem;
    font-size: 1.8rem;
}

.conferma_mobile{
    cursor: pointer;
    margin-top: 0.8rem;
    text-align: center;
    border-radius: 4rem;
    background-color: #146A94;
    border: none;
    color: white;
    height: 3rem;
    width: 11rem;
    font-size: 1.8rem;
}

.elimina{
    background-image: url(/assets/images/bin.png);
    height: 3rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 3.8rem;
    margin-left: 15rem;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.elimina_mobile{
    background-image: url(/assets/images/bin.png);
    height: 3rem;
    width: 4.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 0.8rem;
    border-radius: 4rem;
    background-color: #C93F10;
    border: none;
    background-position: center;
}

.lista_transazioni_sessioni{
    height: calc(100% - 11rem);
    border-radius: 1rem;
    background-color: rgb(51, 51, 51);
    overflow-x: hidden;
    overflow-y: scroll;
}

.lista_transazioni_sessioni::-webkit-scrollbar {
    display: none;
}

.elementi_lista_transazioni {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    height: 4rem;
    border-radius: 1rem;
    background-color: white;
    color: black;
}

.table_element {
    text-align: center;
    margin: 0.5rem;
    margin-top: 1.1rem;
    height: 2rem;
    font-size: 1.6rem;
    background-color: white;
    color: black;
    overflow: hidden;
}

.topBar {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    height: 3rem;
    border-radius: 1rem;
    background-color: #D2D2D2;
    color: black;
    overflow-y: hidden;
}

.topBar::-webkit-scrollbar {
    display: none;
}

.topBar_container{
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
}

.topBar_container::-webkit-scrollbar {
    display: none;
}

.right-block-table{
    overflow-x: scroll;
}

.right-block-table::-webkit-scrollbar {
    display: none;
}

.bar_element {
    text-align: center;
    margin: 0.5rem;
    margin-top: 0.8rem;
    height: 1.2rem;
    font-size: 1.2rem;
    background-color: #D2D2D2;
    color: black;
    overflow: hidden;
}

.popup {
    width: 40rem;
    height: 25rem;
    border-radius: 1rem;
    background-color: #F2F2F2;
    color: black;
    box-shadow: black 0px 54px 55px, black 0px -12px 30px, black 0px 4px 6px, black 0px 12px 13px, black 0px -3px 5px;
}

.popup_mobile {
    width: calc(100% - 3.2rem);
    height: 100%;
    padding-bottom: 1rem;
    margin-left: 1.4rem;
    background-color: #F2F2F2;
    border-radius: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.popup_mobile::-webkit-scrollbar {
    display: none;
}

.popup_title {
    font-weight: bold;
    padding-top: 0.5rem;
    font-size: 3.5rem;
    color: #C93F10;
    text-align: center;
}

.popup_button{
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 2rem;
    text-align: center;
    border-radius: 4rem;
    border: none;
    color: white;
    height: 3rem;
    width: 11rem;
    font-size: 1.8rem;
}

.popup_actions{
    display: flex;
}

.admin{
    margin-top: 0.5rem;
    background: url(/assets/images/admin.png);
    background-size: 3rem 3rem;
    background-repeat: no-repeat;
    width: 4rem;
    height: 3rem;
}

.exit{
    cursor: pointer;
    margin-top: 0.5rem;
    border: none;
    margin-left: 3.3rem;
    background: url(/assets/images/exit.png);
    background-size: 2.8rem 2.8rem;
    background-repeat: no-repeat;
    width: 2.8rem;
    height: 2.8rem;
}

.up_block {
    display: flex;
    width: calc(100% - 1rem);
    height: 5rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    background-color: #E44311;
    border-radius: 0.8rem;
    color: white;
}

.menu_image{
    cursor: pointer;
    border: none;
    background: url(/assets/images/menu.png);
    margin-left: 2rem;
    margin-top: 1.25rem;
    background-size: 2.2rem 2.2rem;
    background-repeat: no-repeat;
    width: 2.2rem;
    height: 2.2rem;
}

.logo_dashboard_mobile{
    border: none;
    background: url(/assets/images/logo.png);
    background-size: 12rem, 4rem;
    margin-left: calc(50vw - 10rem);
    margin-top: 0.8rem;
    background-repeat: no-repeat;
    width: 15rem;
    height: 4rem;
}

.bottom_block_mobile{
    position: absolute;
    width: calc(100% - 3.8rem);
    height: calc(100% - 10rem);
    margin-top: 7rem;
    margin-left: 0.5rem;
    background-color: rgb(51, 51, 51);
    border-radius: 0.8rem;
    padding: 0.8rem;
    color: white;
    overflow-y: scroll;
}

.bottom_block_mobile::-webkit-scrollbar {
    display: none;
}

.bottom_lista_mobile{
    display: flex;
    margin-top: 0.32rem;
    padding-right: 0.6rem;
    padding-top: 0.01rem;
    text-align: left;
    width: 100%;
    height: 3rem;
}

.flex_sessioni_transazioni{
    display: flex;
}

.search_bar {
    border: none;
    width: 100%;
    height: 2.5rem;
    margin-top: 0.5rem;
    background-color: white;
    font-size: 2rem;
    border-radius: 4rem;
    padding-left: 1.2rem;
    text-decoration: underline;
    text-decoration-color: black;
    color: black;
}

.search_bar:placeholder-shown {
    text-decoration-color: #777777;
}

.titles{
    margin: 0rem;
    margin-left: 2rem;
    font-size: 2.5rem;
    text-align: left;
}